<template>
  <div>
    <el-row class="bgW">
      <el-col :span="20">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="活动名称">
            <el-input
              v-model="search.name"
              maxlength="30"
              class="w150"
              clearable
              placeholder="请输入活动名称"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="hint('','add')">添加</el-button>
      </el-col>
    </el-row>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="ID" prop="id" />
          <ElTableColumn label="活动名称" prop="name" />
          <ElTableColumn label="开始时间" prop="start_time" />
          <ElTableColumn label="结束时间" prop="end_time" />
          <ElTableColumn label="操作" width="180" fixed="right">
            <template slot-scope="{ row }">
              <span class="theme" @click="tapBtn('抽奖详情',row)">查看</span>
<!--              <span class="theme" @click="hint(row,'update')">编辑</span> -->
                <span class="peril" @click="updateStatus(row.id)" v-if="row.status==1"> 下架</span>
                <span class="peril" @click="updateStatus(row.id)" v-if="row.status==0"> 上架</span>
            </template>
          </ElTableColumn>
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
      <el-dialog
        title="奖品"
        :visible.sync="dialogVisible"
        width="30%"
        >
        <el-form
          ref="detail"
          label-position="right"
          label-width="170px"
          :model="detail"
          class="formBox"
        >

          <el-form-item label="活动名称:" prop="name">
            <el-input
              v-model="detail.name"
              class="w300"
              maxlength="150"
              placeholder="请输入活动名称"
              show-word-limit
            />
          </el-form-item>
           <el-form-item label="日期">
            <el-date-picker
              v-model="detail.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="detail.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </span>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getMiniEventList,updateMiniPrizeEvent,updateMiniPrizeEventStatus } from "@/api/sys";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  name = ""; // 活动名称
}
class Detail {
  name="";
  id = "";
  start_time = "";
  end_time = "";
}

export default {
  name: "MiniEventList",
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(), // 分页
      search: new Search(), // 检索条件
      list: [], // 数据展示
      detail: new Detail(),
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      getMiniEventList({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.total;
      });
    },
    // 按钮
    tapBtn(routerName, row) {
      var query = {
        id: row.id,
      };
      this.$router.push({ name: routerName, query });
    },
    hint(row,type){
      if(type == "update")
      {
        this.detail = row;
      }else{
        this.detail = new Detail();
      }
      this.dialogVisible = true;
    },
    submit(){
      updateMiniPrizeEvent({ ...this.detail}).then(res => {
        if(res.code == 200)
        {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.dialogVisible = false;
          this.getList();
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      });
    },
    updateStatus(id)
    {
      updateMiniPrizeEventStatus({id:id}).then(res=>{
        if(res.code==200)
        {
          this.$message({
            message: '修改完成',
            type: 'success'
          });
          this.getList();
        }else{
          this.$message.error('修改失败');
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
